<!--Módulo de Gestión de Avisos de Facturación -->

<!-- Avisos Facturación

funescom -> expediente -> avisos

fnt:        num, get, set
tip:         *get      1=avisos incidencias,2=recep.documentos
std:        *get      0,1 Pendientes, 2=Vistos
id:           *set       Id del aviso
txt:         *set       Texto respuesta observaciones
env:       *set       Envio Respuesta 0=no,1=email,2=sms
ema       *set       Email para envio
sms        *set       Telefono para sms -->

<template>
  <div class="avisosFras" v-if="schema && loaded">   
    <v-dialog v-model="dialog" content-class="modal" persistent> 
    
      <!-- Cabecera -->
      <div class="cabecera" v-if="Entorno.header.header">       
        <base_Header 
          :Entorno="Entorno.header"
          @onEvent="cerrar">          
        </base_Header>
      </div>

      <v-sheet :elevation="4">
        <div class="contenedor" style="width:1200px">
           
          <btraextra
            :btra_permisos="btra_permisos"
            :btra_particular="Entorno.btra"                  
            btra_modulo="standard"
            @onEvent="event_btraExtra">
          </btraextra>
    
          <!-- Columna Listado -->
          <div class="conflex" style="padding-top:10px">
            <div class="columna" style="width:100%">
              <div class="conflex">
                <v-select
                  style="flex: 1 1 30%"
                  v-bind="$select"
                  v-model="schema.ctrls.estado.value"
                  :label="schema.ctrls.estado.label"            
                  :items="itemsEstados"
                  item-value="id"
                  item-text="name"                  
                  @change="changeEstado">                  
                </v-select>

                <v-text-field
                  style="flex: 1 1 50%"
                  v-bind="$input"
                  v-model="schema.ctrls.txt.value"
                  :label="schema.ctrls.txt.label"
                  append-icon="mdi-magnify"
                  @keypress.13="filterAviso"
                  @click:append="filterAviso"> 
                </v-text-field>

                <div v-if="Number($store.state.G.USUDAT.ndep)>= 6" style="flex: 1 1 20%" >
                  <v-select                 
                    v-bind="$select"
                    v-model="schema.ctrls.tipo.value"
                    label="Tipo"            
                    :items="itemsTipo"
                    item-value="id"
                    item-text="name"
                    @change="changeTipo">                                   
                  </v-select>
                </div>
              </div>        

              <v-sheet v-bind="$cfg.styles.marco">
                <!-- Grid -->
                <base_Fgrid
                  v-model="selected"
                  :padre="stName"
                  :Entorno="Entorno"          
                  :items-per-page=100
                  show-select
                  single-select 
                  fixed-header  
                  height="410px"                   
                  dense>                         
    
                  <!-- Slot botonera fallename -->
                  <template v-slot:fallename="{ Sitem }">    
                    {{ Sitem.fallename }}
                    <div class="light-blue--text text--darken-3"> {{ Sitem.cta }}</div>
                  </template>

                  <!-- Slot botonera fallename -->
                  <template v-slot:fra="{ Sitem }">    
                    {{ Sitem.fra }}
                    <div class="light-blue--text text--darken-3"> {{ Sitem.fhf|fecha }}</div>
                  </template>

                  <!-- Slot botonera documento -->
                  <template v-slot:documento="{ Sitem }">    
                    {{ Sitem.nom }}
                    <div class="light-blue--text text--darken-3"> {{ Sitem.obs }}</div>
                  </template>

                </base_Fgrid>
              </v-sheet> 
            </div>
          </div>
        </div> 
      </v-sheet>

      <!-- Componentes dinámicos -->      
      <v-dialog
        v-model="modal"
        content-class="modal"
        persistent>
          <div class="centrado" v-if="selected.length">
            <component                 
              :is="componente_dinamico"
              :padre="stName"
              accion="0"
              :accionRow="record"
              :auxRecord="selected.length? selected[0] : {}"
              :filtro="filtro"
              componenteTipo="FC"
              :disparo="disparo"
              @onEvent="$event.accion=='cerrar' || $event.accion==6? cerrar_dinamico() : ''">
            </component>           
          </div>
      </v-dialog> 
    </v-dialog>    
  </div>
</template>



<script>

  import { mixinFinder } from "@/mixins/mixinFinder.js";
  import plugs from "@/common/general_plugs";
  const base_Header = () => plugs.groute("base_Header.vue", "base");
  const base_Fgrid = () => plugs.groute("base_Fgrid_maub.vue", "base");
  const btraextra = () => plugs.groute("btraextra_new.vue", "comp");
  const exp_M = () => plugs.groute("exp_M.vue", "comp");
  const fras_Exp_F = () => plugs.groute("fras_Exp_F.vue", "comp");
  const avisosFras_email = () => plugs.groute("avisosFras_email.vue", "comp"); 
    
  export default {
    mixins: [mixinFinder],
    components: { base_Header, base_Fgrid, btraextra, exp_M, fras_Exp_F, avisosFras_email },
    props: {
      padre: { type:String, default: '' }, 
      stilob: { type: String, default: "" },
      titulo: { type: String, default: ''},
      icono: { type: String, default: 'mdi-bell'},
      stiloi: { type: String, default: "" },
      colorBadge: { type: String, default: "" },
      aviso: { type: Number, default: 0},
      timer: { type: Number, default: 60},
      show: { type: [String, Number], default: "1" },    
      componenteTipo: { type:String, default:'F' },
      Entorno: { type:Object, default: function() { return JSON.parse(JSON.stringify(this.$cfg.base.F)); }}
    },

    data() {
      return {
        schema: {
          ctrls: {
            estado: { f:'estado', label:'Estado', value:'1' },
            txt: { f:'txt', label:'Busqueda', value:'' },
            tipo: { f:'tipo', label:'', value:'0' }                      
          }
        },
        api:'',
        stName:'stMavisosExp',
        dialog:true,
        selected:[],
        itemsEstados: [],
        itemsTipo: [],

        btra_permisos:{ extra:{expedientes:1, facturacion:1, ver_documento:1 }},

        record:{},
        filtro:'',
        componente_dinamico:null,
        modal:false,
        disparo:false,

        modalEma:false,
        disparoEma:false,
        loaded:false
      };
    },


    methods: {
      ini_data() {
        console.log("DEV " + this.api + " ********************** (ini_data)");

        // guardo del store en variables locales
        //this.schema = this.$store.state[this.stName].schema;        
        
        // configuro Entorno 
        this.Entorno.header.titulo = 'Avisos Documentación'; 
        this.Entorno.btra= JSON.parse(JSON.stringify(this.$cfg.btra_tipo["F"]));        
        this.Entorno.btra.footer.extra = [
          { accion:"expedientes", icono: "mdi-alpha-e-circle-outline", title:'Ver/Editar Expediente' },
          { accion:'facturacion', icono: "mdi-alpha-f-circle-outline", title:'Facturación' },
          { accion:'ver_documento', btn: "btn_pdf", title:'Ver documento' },
          { accion:'visto', icono: "mdi-check",  title:'Visto / OK' },
          { accion:'visto_email', icono: "mdi-mail", title:'Enviar Email' },
        ]; 

        this.headers = {
          header: [
            { text: "Fecha", value: "fh", witdh: "10%", filtro:"fechahr" },
            { text: "Control", value: "control", witdh: "10%" },
            { text: "Fallecido / Proveedor", value: "fallename", witdh: "10%", slot:true },
            { text: "Factura", value: "fra", witdh: "10%", slot:true },
            { text: "Documento / Obs", value: "documento", witdh: "10%", slot:true },
          ],

        };

        this.Entorno.grid.headers = this.set_headers();
        this.Entorno.grid.autoload= true;

        // configuro botonera
        this.botoneras_set();

        // items adicionales
        // array de estados de los avisos
        this.itemsEstados= [
          { id:"1", name:'Pendientes' },
          { id:"2", name:'Vistos / OK' }
        ];

        this.itemsTipo= [
          { id:"0", name:'(Todos)' },
          { id:"1", name:'CTD' },
          { id:"2", name:'ADMON' }
        ];
      },

      //
      async ini_component() {
        let args = { tipo:'fnc', accion: 'expediente', fn_args: { 
          accion:'avisos',
          fnt:'get',
          tip:2,
          std:this.schema.ctrls.estado.value
        }};
       
        let apiResult = await this.$store.dispatch("ajaxRequest", { args:args });        
        if (apiResult.sql.error) {
          this.$root.$alert.open(apiResult.sql.msg, 'error');
          return;
        }

        // guardo records del Grid todos los arrays recibidos, en 'recordsDetalle'
        this.$store.commit(this.stName + '/update_records', apiResult.r[0]);
        this.$store.commit(this.stName + '/data2State', { prop:'recordsDetalle', value: apiResult.r }); 

        // records cargados
        this.loaded= true;
      },

      
      //
      event_btraExtra(evt) {
        this[evt.accion](evt);
      },


      // configuro estado botones
      botoneras_set() {
        this.btnSet("expedientes", { disabled:!this.selected.length? true : false });
        //this.btnSet("facturacion", { disabled:!this.selected.length? true : false });
        this.btnSet("facturacion", { disabled:true });
        this.btnSet("ver_documento", { disabled:!this.selected.length? true : false });
        this.btnSet("visto", { disabled:!this.selected.length || this.selected[0].visto? true : false });
        this.btnSet("visto_email", { disabled:!this.selected.length || this.selected[0].visto? true : false });     
      },


      // muestro ventana expedientes
      expedientes() {
        if (!this.selected.length) {
          this.$root.$alert.open('Seleccione un Aviso', 'info');
          return;
        }

        if (this.$router)
          if (this.$route.path=='/expedientes') {          
          //let pathname=window.location.pathname.split('/');        
          //if (pathname[pathname.length - 1]== 'expedientes') {          
            this.$router.push({ path:'/' });
        }       
        
        this.record= { id:this.selected[0].exp_id };
        this.componente_dinamico= "exp_M";
        this.disparo= !this.disparo;
        this.modal= true;
      },

      // muestro ventana facturación
      facturacion() {
        this.filtro= "#" + this.selected[0].exp_id;        
        this.componente_dinamico= "fras_Exp_F";
        this.disparo= !this.disparo;
        this.modal=true;
      },


      //
      async ver_documento() {
        let args = { tipo:'fnc', accion: 'documentos', fn_args: { 
          accion:'fichero',
          tip:0,
          //id:this.selected[0].id
          doc:this.selected[0].doc
        }};
        
        let apiResult = await this.$store.dispatch("ajaxRequest", { args:args });         
        if (apiResult.sql.error) {
          this.$root.$alert.open(apiResult.sql.msg, 'error');
          return;
        }

        // abro documento
        window.open(this.$store.state.G.url_tmp + apiResult.r[0], "_blank");        
      },


      // 
      visto_email() {
        this.componente_dinamico='avisosFras_email';
        this.modal= true;       
      },


      //
      visto() {
        this.$root.$alert.open('VISTO / OK Documento<br>¿Aceptamos como VISTO el Documento recibido?' , 'confirm', null, ["ACEPTAR", "CANCELAR"])
        .then(r=> { if (r==0) this.visto_continue() });
      },

      async visto_continue() {
        let args = { tipo:'fnc', accion: 'expediente', fn_args: { 
          accion:'avisos',
          fnt:'set',
          tip:2,
          id:this.selected[0].id,
    
        }};
      
        let apiResult = await this.$store.dispatch("ajaxRequest", { args:args });       
        if (apiResult.sql.error) {
          this.$root.$alert.open(apiResult.sql.msg, 'error');
          return;
        }

        // limpio selected
        this.selected= [];

        // actualizo grid con los records recibidos
        this.$store.commit(this.stName + '/update_records', apiResult.r[0]);
        this.$store.commit(this.stName + '/data2State', { prop:'recordsDetalle', value: apiResult.r }); 
      },


      // cambio el select Estado
      async changeEstado() {
        await this.ini_component();
        this.filterAviso();
      },


      //
      async changeTipo() {        
        this.$store.commit(this.stName + '/data2State', { prop:'records', value:this.miSt.read_records.slice().filter(elem=> {
          if (Number(this.schema.ctrls.tipo.value)> 0) return elem.tipo== this.schema.ctrls.tipo.value;
          return elem;
        })});
      },


      //
      filterAviso() {
        let txt= this.schema.ctrls.txt.value;
        
        this.$store.commit(this.stName + '/data2State', { prop:'records', value:this.miSt.read_records.slice().filter(elem=> {
    
          return (
            elem.control.toLowerCase().includes(txt.toLowerCase()) ||
            elem.fallename.toLowerCase().includes(txt.toLowerCase()) ||
            elem.cta.toLowerCase().includes(txt.toLowerCase()) || 
            elem.fra.toLowerCase().includes(txt.toLowerCase())
          )
          
        })})

        this.selected= [];
        if (!this.miSt.records.length) return;
        this.accionRow= this.miSt.records[0];
      },


      // 
      cerrar_dinamico() {
        // obtengo los avisos de expedientes
        this.modal= false;
        this.componente_dinamico= null;
      },


      //
      cerrar() {
        this.$emit('aviso', {accion:null});

        // obtengo los avisos de expedientes
        if (this.$root.$avisos_campana) this.$root.$avisos_campana.recuperaN();
      }

    },


     watch: {
      selected() {
        this.botoneras_set();
      }
    }
  };
</script>
